import React from 'react';
import PropTypes from 'prop-types';
import { withBrand } from '../../../../../../providers/BrandProvider';
import GoogleMapEmbed from '../../../../../shared/GoogleMapEmbed/GoogleMapEmbed';
import { RIGHT } from '../../constants';
import { GoogleMapComponentWrapper } from '../../styles';

const GoogleMapComponent = ({
  longitude,
  latitude,
  position,
  brand: { primaryColor } = {},
}) => {
  const mapCenterPositionOffset = position === RIGHT ? 0.005 : -0.005;

  return (
    <GoogleMapComponentWrapper data-testid="venue-google-embed">
      <GoogleMapEmbed
        center={{
          lat: latitude,
          lng: longitude + mapCenterPositionOffset,
        }}
        zoom={15}
        markers={[
          {
            lat: latitude,
            lng: longitude,
          },
        ]}
        data={{ icon: { fillColor: primaryColor } }}
      />
    </GoogleMapComponentWrapper>
  );
};

GoogleMapComponent.propTypes = {
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  position: PropTypes.oneOf(['RIGHT', 'LEFT']),
  brand: PropTypes.shape({
    primaryColor: PropTypes.string,
  }),
};

export default withBrand(GoogleMapComponent);

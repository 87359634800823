import styled from 'styled-components';
import { devices } from '../../../../helpers';

export const GoogleMapComponentWrapper = styled.div`
  position: relative;
  height: 320px;

  @media only screen and (min-width: ${devices.breakpoints.tablet}) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
  }
`;

import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const VenueModuleCallToAction = styled.div`
  &&& {
    a {
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.vStyles.elements?.venueCallToAction,
        )}
      &:hover {
        ${({ theme }) =>
          renderStyledElementStyles(
            theme,
            theme.module.vStyles.elements?.venueCallToAction?.hover,
          )}
      }
    }
  }
`;

import styled from 'styled-components';
import { devices } from '../../../../helpers';

export const ImageWrapper = styled.div`
  padding-top: 42%;
  background: center no-repeat;
  background-size: cover;

  @media only screen and (min-width: ${devices.breakpoints.tablet}) {
    display: none;
  }
`;

import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const Box = styled.div`
  border-top: 4px solid;

  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements.box)}
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.box)}
`;

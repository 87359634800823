import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const ModuleWrapper = styled.div`
  position: relative;
  background: center no-repeat;
  background-size: cover;
  padding: 0;

  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.siteBuilderModules.venueModule)}
`;

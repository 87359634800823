import React from 'react';
import PropTypes from 'prop-types';
import { ImageWrapper } from '../../styles';

export const VenueImage = ({ backgroundImage }) => (
  <ImageWrapper
    data-background-image={backgroundImage}
    className="lazy"
    data-testid="venue-module-image"
  />
);

VenueImage.propTypes = {
  backgroundImage: PropTypes.string,
};

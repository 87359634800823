import React from 'react';
import PropTypes from 'prop-types';
import { isNode } from '../../../helpers';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import clientConfig from '../../../clientConfig';

const defaultMarkerIcon = {
  path: 'M11.513,12.38c-2.117,0-3.835-1.729-3.835-3.862c0-2.135,1.718-3.863,3.835-3.863s3.835,1.729,3.835,3.863  C15.348,10.65,13.63,12.38,11.513,12.38 M11.513,0C6.825,0,3.025,3.827,3.025,8.549c0,4.46,3.844,10.213,6.411,13.014  c0.959,1.045,2.076,2.454,2.076,2.454s1.2-1.417,2.229-2.493C16.306,18.84,20,13.451,20,8.549C20,3.827,16.2,0,11.513,0',
  fillOpacity: 1,
  strokeWeight: 0,
  scale: 1.5,
};

const defaultMapStyles = [
  {
    featureType: 'all',
    elementType: 'all',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [{ weight: '0.5' }, { visibility: 'on' }],
  },
  {
    featureType: 'administrative',
    elementType: 'labels',
    stylers: [{ visibility: 'simplified' }],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text',
    stylers: [{ lightness: '-50' }, { saturation: '-50' }],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text',
    stylers: [
      { hue: '#009aff' },
      { saturation: '25' },
      { lightness: '0' },
      { visibility: 'simplified' },
      { gamma: '1' },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      { saturation: '0' },
      { lightness: '100' },
      { gamma: '2.31' },
      { visibility: 'on' },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'labels',
    stylers: [
      { visibility: 'simplified' },
      { lightness: '20' },
      { gamma: '1' },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'labels.text.fill',
    stylers: [{ saturation: '-100' }, { lightness: '-100' }],
  },
  {
    featureType: 'landscape',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'all',
    stylers: [{ visibility: 'simplified' }],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      { lightness: '0' },
      { saturation: '45' },
      { gamma: '4.24' },
      { visibility: 'simplified' },
      { hue: '#00ff90' },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ saturation: '-100' }, { color: '#f5f5f5' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text',
    stylers: [{ visibility: 'simplified' }, { color: '#666666' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ saturation: '-25' }],
  },
  {
    featureType: 'transit.line',
    elementType: 'all',
    stylers: [{ visibility: 'simplified' }],
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit.station.rail',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [{ visibility: 'on' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [{ lightness: '50' }, { gamma: '.75' }, { saturation: '100' }],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [{ visibility: 'simplified' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
];

function GoogleMapEmbed(props) {
  if (isNode()) {
    return null;
  }

  const {
    center,
    zoom = 15,
    bounds = [],
    markers = [],
    height = '100%',
    visible = true,
    data = {},
  } = props;

  const mapBounds = new props.google.maps.LatLngBounds();
  for (let i = 0; i < bounds.length; i++) {
    mapBounds.extend(bounds[i]);
  }

  const mapIconOptions = Object.assign({}, defaultMarkerIcon, data.icon || {});

  return (
    visible && (
      <Map
        google={props.google}
        style={{
          position: 'relative',
          overflow: 'hidden',
          height,
        }}
        styles={defaultMapStyles}
        initialCenter={{
          lat: center.lat,
          lng: center.lng,
        }}
        zoom={zoom}
        draggable
        scrollwheel={false}
        disableDefaultUI
        bounds={bounds.length ? mapBounds : null}
      >
        {markers.map((marker, index) => (
          <Marker
            key={index}
            title=""
            name=""
            icon={mapIconOptions}
            position={{
              lat: marker.lat,
              lng: marker.lng,
            }}
          />
        ))}
      </Map>
    )
  );
}

GoogleMapEmbed.propTypes = {
  center: PropTypes.object,
  zoom: PropTypes.number,
  bounds: PropTypes.array,
  markers: PropTypes.array,
  height: PropTypes.number,
  visible: PropTypes.bool,
  google: PropTypes.object,
  data: PropTypes.shape({
    icon: PropTypes.object,
  }),
};

export default GoogleApiWrapper({
  apiKey: clientConfig.google.maps.apiKey,
})(GoogleMapEmbed);

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { SiteBuilderModule } from '../sharedComponents';
import { getImgixUrl } from '../../../../helpers';
import { IMAGE, MAP, RIGHT } from './constants';
import GoogleMapComponent from './innerComponents/GoogleMapComponent';
import { VenueImage } from './innerComponents/VenueImage';
import { BoxDisplayStyle } from './displayStyles/BoxDisplayStyle';
import { ModuleWrapper, SectionWrapper } from './styles';

const VenueModule = (props) => {
  const {
    section: {
      title,
      text,
      longitude,
      latitude,
      background: { path: backgroundImagePath } = {},
      backgroundStyle = IMAGE,
      position = RIGHT,
      link: ctaConfig,
    } = {},
    pageConfig: { tenantId } = {},
  } = props;

  const themeConfig = useContext(ThemeContext);
  const { venueModule } = themeConfig.siteBuilderModules;
  const vStyles = venueModule.visualStyles.DEFAULT;
  const dStyles = venueModule.displayStyles[position];

  const {
    box: {
      contentGrid: { xs, sm, offset, rtl },
    },
  } = dStyles.elements;

  const contentOffset = themeConfig.globalVariables.isRTL ? rtl.offset : offset;

  const backgroundImage = getImgixUrl(tenantId, backgroundImagePath);

  return (
    <SiteBuilderModule
      section={props.section}
      moduleTheme={{ dStyles, vStyles }}
    >
      <ModuleWrapper
        data-background-image={backgroundImage}
        className="lazy"
        data-testid="venue-module-wrapper"
      >
        {backgroundStyle === MAP && (
          <GoogleMapComponent
            backgroundStyle={backgroundStyle}
            position={position}
            latitude={latitude}
            longitude={longitude}
          />
        )}
        {backgroundStyle === IMAGE && (
          <VenueImage
            backgroundImage={backgroundImage}
            backgroundStyle={backgroundStyle}
          />
        )}
        <SectionWrapper data-testid="venue-module-section">
          <div className="container">
            <div className="row">
              <div
                className={`col-xs-${xs} col-sm-${sm} col-sm-offset-${contentOffset}`}
                data-testid="venue-box-display-style"
              >
                <BoxDisplayStyle
                  title={title}
                  text={text}
                  ctaConfig={ctaConfig}
                />
              </div>
            </div>
          </div>
        </SectionWrapper>
      </ModuleWrapper>
    </SiteBuilderModule>
  );
};

VenueModule.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    position: PropTypes.oneOf(['RIGHT', 'LEFT']),
    link: PropTypes.shape({
      type: PropTypes.string,
    }),
    background: PropTypes.shape({
      path: PropTypes.string,
    }),
    backgroundStyle: PropTypes.oneOf(['IMAGE', 'MAP']),
  }).isRequired,
  pageConfig: PropTypes.shape({
    tenantId: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  pageConfig: state.pageConfig,
});

export default connect(mapStateToProps)(VenueModule);

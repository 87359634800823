import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { renderHTMLString } from '../../../../../../helpers';
import { StyledCallToAction } from '../../../sharedComponents';
import {
  Title,
  Box,
  SubTitle,
  Text,
  VenueModuleCallToAction,
} from '../../styles';

export const BoxDisplayStyle = ({ title, text, ctaConfig }) => {
  const { t } = useTranslation();

  const themeConfig = useContext(ThemeContext);
  const dStyles = themeConfig.module.dStyles;
  const vStyles = themeConfig.module.vStyles;

  const {
    callToAction: {
      settings: { btnLook, mode },
    },
  } = vStyles.elements;

  return (
    <Box>
      <Title>{t('event.venue')}</Title>
      <SubTitle>{title}</SubTitle>
      <Text>{renderHTMLString(text)}</Text>
      {ctaConfig.shown && (
        <VenueModuleCallToAction>
          <StyledCallToAction
            size="tiny"
            look={btnLook}
            mode={mode}
            ctaConfig={ctaConfig}
            displayStyles={dStyles}
            visualStyles={vStyles}
          />
        </VenueModuleCallToAction>
      )}
    </Box>
  );
};

BoxDisplayStyle.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  ctaConfig: PropTypes.shape({
    type: PropTypes.string,
  }),
};
